var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(
        _vm.periodTeachers.period_enable === '1' &&
          _vm.periodTeachers.period_type === 'teacher'
      )?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account")]),_vm._v(" ข้าราชการครู ")],1),_c('h2',[_vm._v("สามารถดำเนินการ "),_c('u',[_vm._v("ยื่นคำร้องขอย้าย")])]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.periodTeachers.period_stop,"add", "543 years"),"D MMMM YYYY"))+" เวลา 16.30 น. ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ "),_c('u',[_vm._v("ยื่นคำร้องขอย้าย")]),_vm._v(" : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.periodTeachers.period_stop,"add", "543 years"),"D MMMM YYYY"))+" เวลา 16.30 น. "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.periodTeachers.period_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [(
        _vm.period_38.period_enable === '1'
      )?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account")]),_vm._v(" บุคลากรทางการศึกษาอื่นตามมาตรา 38 ค.(2) ")],1),_c('h2',[_vm._v("สามารถดำเนินการ "),_c('u',[_vm._v("ยื่นคำร้องขอย้าย")])]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_38.period_stop,"add", "543 years"),"D MMMM YYYY"))+" เวลา 16.30 น. ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ "),_c('u',[_vm._v("ยื่นคำร้องขอย้าย")]),_vm._v(" : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_38.period_stop,"add", "543 years"),"D MMMM YYYY"))+" เวลา 16.30 น. "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_38.period_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }