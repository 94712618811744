var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.period_sedirector_notice.period_sedirector_enable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"blue--text"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-school")]),_vm._v(" ประกาศ ")],1),_c('h2',[_vm._v("รายชื่อผู้มีสิทธิเข้ารับการคัดเลือก "),_c('u',[_vm._v(" ตำแหน่ง รองผู้อำนวยการสถานศึกษา")])]),_c('h3',[_vm._v(" ผู้สมัคร และ วิทยาลัยสามารถตรวจสอบข้อมูลผ่านระบบ ")]),_c('h4',[_vm._v(" * เฉพาะวิทยาลัยที่ปรากฎผู้สมัคร ")])]),_c('v-col',{staticClass:"shrink"})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ วันที่ : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_sedirector_notice.period_sedirector_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_sedirector_notice.period_sedirector_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.period_college_assistantA.period_college_enable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"blue--text"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-school")]),_vm._v(" วิทยาลัย ")],1),_c('h2',[_vm._v("ประเมิน "),_c('u',[_vm._v(" ภาค ก ตำแหน่ง รองผู้อำนวยการ")])]),_c('h3',[_vm._v(" ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_college_assistantA.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" ")]),_c('h4',[_vm._v(" * เฉพาะวิทยาลัยที่ปรากฎผู้สมัคร ")])]),_c('v-col',{staticClass:"shrink"})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ วันที่ : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_college_assistantA.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_college_assistantA.period_college_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.period_college_redirector.period_college_enable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"blue--text"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-school")]),_vm._v(" วิทยาลัย ")],1),_c('h2',[_vm._v("รับรายงานตัวครู "),_c('u',[_vm._v(" รองผู้อำนวยการ")])]),_c('h3',[_vm._v(" ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_college_redirector.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" ")]),_c('h4',[_vm._v(" * เฉพาะวิทยาลัยที่ได้รับ รองผู้อำนวยการวิทยาลัย ")])]),_c('v-col',{staticClass:"shrink"})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ วันที่ : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_college_redirector.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_college_redirector.period_college_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(
        _vm.period_colleges_reportmv.period_college_enable === '1' &&
          _vm.period_colleges_reportmv.period_college_type ===
            'reportmove_teacher'
      )?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"blue--text"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-school")]),_vm._v(" วิทยาลัย ")],1),_c('h2',[_vm._v("รับรายงานตัวครู "),_c('u',[_vm._v(" ย้ายกรณีปกติ")])]),_c('h3',[_vm._v(" ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_colleges_reportmv.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" ")]),_c('h4',[_vm._v(" * เฉพาะวิทยาลัยที่ได้รับครูจากการย้ายกรณีปกติตามประกาศ ")])]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.viewDataMoveTeach()}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" ตรวจสอบรายชื่อ")],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ วันที่ : "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_colleges_reportmv.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_colleges_reportmv.period_college_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [(
        _vm.period_colleges.period_college_enable === '1' &&
          _vm.period_colleges.period_college_type === 'update_college'
      )?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"blue--text"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-school")]),_vm._v(" วิทยาลัย ")],1),_c('h2',[_vm._v("รายงาน"),_c('u',[_vm._v("อัตรากำลัง")])]),_c('h3',[_vm._v(" ให้วิทยาลัยดำเนินการ ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_colleges.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ งานอัตรากำลังวันที่ : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_colleges.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_colleges.period_college_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [(
        _vm.period_teacher_requests.period_college_enable === '1' &&
          _vm.period_teacher_requests.period_college_type === 'teacher_request'
      )?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"blue--text"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-school")]),_vm._v(" วิทยาลัย ")],1),_c('h2',[_vm._v(" ดำเนินการ "),_c('u',[_vm._v("แนบไฟล์แบบคำร้องขอย้ายและเอกสารประกอบการพิจารณา ")])]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_teacher_requests.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ "),_c('u',[_vm._v("ยื่นคำร้องขอย้าย")]),_vm._v(" : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_teacher_requests.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_teacher_requests.period_college_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [(
        _vm.period_college_moves.period_college_enable === '1' &&
          _vm.period_college_moves.period_college_type === 'movement_college'
      )?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"blue--text"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-school")]),_vm._v(" วิทยาลัย ")],1),_c('h2',[_vm._v(" ดำเนินการ "),_c('u',[_vm._v("แจ้งเงื่อนไขสาขาวิชาและจำนวนที่ต้องการรับย้าย ")])]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_college_moves.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" เวลา 16.30 น. ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ "),_c('u',[_vm._v("ยื่นคำร้องขอย้าย")]),_vm._v(" : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_college_moves.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" เวลา 16.30 น. "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_college_moves.period_college_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [(
        _vm.period_college_moves_attactment.period_college_enable_file === '1' &&
          _vm.period_college_moves_attactment.period_college_type ===
            'movement_college'
      )?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"blue--text"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-school")]),_vm._v(" วิทยาลัย ")],1),_c('h2',[_vm._v(" ดำเนินการ "),_c('u',[_vm._v("แนบไฟล์เงือนไขสาขาวิชา ")])]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_college_moves_attactment.period_college_attactmentstop,"add", "543 years"),"D MMMM YYYY"))+" เวลา 16.30 น. ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ "),_c('u',[_vm._v("ยื่นคำร้องขอย้าย")]),_vm._v(" : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_college_moves_attactment.period_college_attactmentstop,"add", "543 years"),"D MMMM YYYY"))+" เวลา 16.30 น. "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_college_moves_attactment.period_college_attactmentstop +
                  ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [(
        _vm.period_teacher_moves.period_college_enable === '1' &&
          _vm.period_teacher_moves.period_college_type === 'discuss_teacher'
      )?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"blue--text"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-school")]),_vm._v(" วิทยาลัย ")],1),_c('h3',[_vm._v(" ดำเนินการขอความคิดเห็นจากคณะกรรมการวิทยาลัย "),_c('u',[_vm._v("ต่อผู้ประสงค์ขอย้ายเข้า รับย้ายสายการสอน (ครู)")])]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_teacher_moves.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ รายงานผลการพิจารณา "),_c('u',[_vm._v("รับย้ายสายการสอน")]),_vm._v(" : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.perioddiscuss.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_teacher_moves.period_college_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [(
        _vm.perioddiscuss.period_college_enable === '1' &&
          _vm.perioddiscuss.period_college_type === 'discuss_college'
      )?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"blue--text"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-school")]),_vm._v(" วิทยาลัย ")],1),_c('h3',[_vm._v(" ดำเนินการขอความคิดเห็นจากคณะกรรมการวิทยาลัย "),_c('u',[_vm._v("ต่อผู้ประสงค์ขอย้ายเข้า รับย้ายสายบริหาร")])]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.perioddiscuss.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ ผลการพิจารณา "),_c('u',[_vm._v("รับย้ายสายบริหาร")]),_vm._v(" : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.perioddiscuss.period_college_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.perioddiscuss.period_college_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [(_vm.periodassteachs.periodAssTeachEnable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"blue--text"},[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-school")]),_vm._v(" วิทยาลัย ")],1),_c('h2',[_vm._v(" ดำเนินการ "),_c('u',[_vm._v(" รับรายงานตัว ครูผู้ช่วย ")]),_c('br'),_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.periodassteachs.periodAssTeachStop,"add", "543 years"),"D MMMM YYYY"))+" : เวลา 16:30:00 น. ")])]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.viewData()}}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" ตรวจสอบรายชื่อ")],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ รับรายงานตัว ครูผู้ช่วย : "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.periodassteachs.periodAssTeachStop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.periodassteachs.periodAssTeachStop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('Manage_assistant_teacher',{attrs:{"OpenDialog_manage_assistant_teacher":_vm.OpenDialog_manage_assistant_teachers},on:{"toggle-favorite":_vm.receiveEmit}}),_c('Conditons_transfer_success_Vue',{attrs:{"OpenDialog_conditons_transfer_success":_vm.OpenDialog_conditons_transfer_successs},on:{"toggle-favorite":_vm.receiveEmit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }