<template>
  <v-container id="dashboard" fluid tag="section">
    <div>
      <alertMissionPersonnelAssTeach />
    </div>
    <div>
      <AlertMissionCollege />
    </div>

    <div>
      <alertMissionPersonnelDirector />
    </div>
    <div>
      <alertMissionPersonnelTeach />
    </div>
    <div>
      <alertMissionProvince />
    </div>
    <v-row>
      <v-col cols="12" md="12"> </v-col>
      <v-dialog
        v-model="dialogLoading"
        hide-overlay
        v-if="loading"
        persistent
        width="300"
      >
        <v-card color="primary" dark>
          <v-card-text>
            <h2 class="white--text">Loading</h2>
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-col cols="12" lg="12">
        <v-bottom-navigation :background-color="color" horizontal>
          <v-btn
            id="btnnavi"
            color="primary"
            max-width="100%"
            elevation="3"
            rounded
            to="/pages/login"
            dark
          >
            <h2 class="card-title font-weight-light">
              <v-icon>mdi-account-key</v-icon>
              <span>เข้าสู่ระบบ : ข้าราชการครูและบุคลากรทางการศึกษา</span>
            </h2>
          </v-btn>

          <v-btn
            id="btnnavi"
            color="warning"
            max-width="100%"
            elevation="3"
            rounded
            to="/pages/loginAdmin"
            dark
          >
            <h2 class="card-title font-weight-light">
              <v-icon>mdi-login-variant</v-icon
              ><span>เข้าสู่ระบบ : วิทยาลัย / สอจ.</span>
            </h2>
          </v-btn>
        </v-bottom-navigation>
      </v-col>

      <!-- Component Zone -->
      <v-col cols="12" md="12">
        <ReportPerson/>
      </v-col>

     
     
      <v-col cols="12" md="12">
      <Personnel_etcSummarySubject/> 
      </v-col>
      <v-col cols="12">
        <personnel_etcSummary />
      </v-col>

      <v-col cols="12">
        <Personnel_etcSummaryBranchsub/>
      </v-col>
      <v-col cols="12" md="12">
        <stdsummary />
      </v-col>

      <v-col cols="12" md="12">
        <ReportCollege/>
      </v-col>
      <v-col cols="12" md="12">
        <HrApppoint/>
      </v-col>
      <v-col cols="12" md="12">
        <ReportBranch/>
      </v-col>

  
    </v-row>
  </v-container>
</template>

<script>

import { Bar } from "vue-chartjs";
import FlipCountdown from "vue2-flip-countdown";
import personnel_etcSummary from "./component/personnel_etcSummary";
import stdsummary from "./component/stdsummary";

import AlertMissionCollege from "./components/core/alertMissionCollege.vue";
import alertMissionPersonnelAssTeach from "./components/core/alertMissionPersonnelAssTeach.vue";
import alertMissionPersonnelDirector from "./components/core/alertMissionPersonnelDirector.vue";
import alertMissionPersonnelTeach from "./components/core/alertMissionPersonnelTeach.vue";
import alertMissionProvince from "./components/core/alertMissionProvince.vue";
import HrApppoint from "./components/core/hrApppoint.vue";
import ReportPerson from "./components/core/reportPerson.vue";
import ReportCollege from "./components/core/reportCollege.vue";
import ReportBranch from "./components/core/reportBranch.vue";
import Personnel_etcSummarySubject from "./component/personnel_etcSummarySubject.vue";
import Personnel_etcSummaryBranchsub from "./component/personnel_etcSummaryBranchsub.vue";

export default {
  name: "DashboardDashboard",
  extends: Bar,
  components: {
    FlipCountdown,
    
    personnel_etcSummary,
    stdsummary,
    AlertMissionCollege,
    alertMissionPersonnelAssTeach,
    alertMissionPersonnelDirector,
    alertMissionPersonnelTeach,
    alertMissionProvince,
    HrApppoint,
    ReportPerson,
    ReportCollege,
    ReportBranch,
    Personnel_etcSummarySubject,
    Personnel_etcSummaryBranchsub
  },
  data() {
    return {
      loading: false
    };
  },
  async mounted() {},

  methods: {},
  computed: {
    color() {
      return "blue lighten-4";
    }
  }
};
</script>
<style>
#btnnavi {
  width: 50%;
}
.texth2 {
  font-size: xx-large;
  color: yellow;
}
</style>
