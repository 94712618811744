var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.period_vecprovince_ap.period_vecprovince_enable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',{attrs:{"cols":"12","md":"7"}},[_c('v-col',[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('h2',{staticClass:"secondary--text"},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-domain")]),_vm._v(" สำนักงานอาชีวศึกษาจังหวัด ")],1),_c('h2',[_vm._v(" ตรวจสอบคุณสมบัติ ผู้สมัครคัดเลือก ตำแแหน่งรองผู้อำนวยการ ")]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_vecprovince_ap.period_vecprovince_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_vecprovince_ap.period_vecprovince_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_vecprovince_ap.period_vecprovince_stop +
                  ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [(_vm.period_vecprovince_apdir.period_vecprovince_enable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',{attrs:{"cols":"12","md":"7"}},[_c('v-col',[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('h2',{staticClass:"secondary--text"},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-domain")]),_vm._v(" สำนักงานอาชีวศึกษาจังหวัด ")],1),_c('h2',[_vm._v(" ตรวจสอบคุณสมบัติ ผู้สมัครคัดเลือก ตำแแหน่ง ผู้อำนวยการวิทยาลัย ")]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_vecprovince_apdir.period_vecprovince_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_vecprovince_apdir.period_vecprovince_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_vecprovince_apdir.period_vecprovince_stop +
                  ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [(_vm.period_vecprovince_b.period_vecprovince_enable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',{attrs:{"cols":"12","md":"7"}},[_c('v-col',[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('h2',{staticClass:"secondary--text"},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-domain")]),_vm._v(" สำนักงานอาชีวศึกษาจังหวัด ")],1),_c('h2',[_vm._v(" ประเมิน ภาค ข ตำแแหน่งรองผู้อำนวยการ ")]),_c('h3',[_vm._v(" ครั้งที่ "+_vm._s(_vm.period_vecprovince_b.period_vecprovince_times)+" / "+_vm._s(_vm.period_vecprovince_b.period_vecprovince_year)+" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_vecprovince_b.period_vecprovince_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_vecprovince_b.period_vecprovince_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_vecprovince_b.period_vecprovince_stop +
                  ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [(_vm.period_vecprovinceTeacher.period_vecprovince_enable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',{attrs:{"cols":"12","md":"7"}},[_c('v-col',[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('h2',{staticClass:"secondary--text"},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-domain")]),_vm._v(" สำนักงานอาชีวศึกษาจังหวัด ")],1),_c('h3',[_vm._v(" ดำเนินการ "),_c('u',[_c('br'),_vm._v(" เสนอรายชื่อคณะกรรมการ "),_c('br'),_vm._v(" พิจารณาตรวจสอบการย้าย ตำแแหน่งครู ")]),_c('br'),_vm._v(" ครั้งที่ "+_vm._s(_vm.period_vecprovinceTeacher.period_vecprovince_times)+" / "+_vm._s(_vm.period_vecprovinceTeacher.period_vecprovince_year)+" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_vecprovinceTeacher.period_vecprovince_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ การพิจารณาตรวจสอบการย้าย ตำแแหน่งครู : "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_vecprovinceTeacher.period_vecprovince_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_vecprovinceTeacher.period_vecprovince_stop +
                  ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [(_vm.period_vecprovincemanage.period_vecprovince_enable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',{attrs:{"cols":"12","md":"7"}},[_c('v-col',[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('h2',{staticClass:"primary--text"},[_vm._v("สำนักงานอาชีวศึกษาจังหวัด")]),_c('h3',[_vm._v(" ดำเนินการ "),_c('u',[_vm._v(" พิจารณาตรวจสอบการย้าย ตำแแหน่งครู ")]),_vm._v(" "),_c('br'),_vm._v(" ครั้งที่ "+_vm._s(_vm.period_vecprovincemanage.period_vecprovince_times)+" / "+_vm._s(_vm.period_vecprovincemanage.period_vecprovince_year)+" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_vecprovincemanage.period_vecprovince_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ การพิจารณาตรวจสอบการย้าย ตำแแหน่งครู : "),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_vecprovincemanage.period_vecprovince_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_vecprovincemanage.period_vecprovince_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }