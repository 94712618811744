<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลรายงานอัตรากำลังของวิทยาลัย แยกตามสาขาวิชา"
        class="px-5 py-3"
      >
      <div class="text-right">
        <v-btn href="#/admin/print_personnel_etcSummaryBranch/" target="_blank" fab color="info"><v-icon>mdi-printer</v-icon></v-btn>
      </div>
        
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="personnel_etcs"
          :search="search"        
        >
        <template v-slot:top>
            <div>
                <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
            </div>
        </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>
    </v-container>
  </div>
</template>
<script>
import VueMoment from "vue-moment";
import moment from "moment-timezone";
export default {
  name: "personnel_etc",
  data() {
    return {
      VueMoment,
      moment,
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,

      personnel_etcs: [],
      search: "",
      pagination: {},
      headers: [
        { text: "ประเภทวิชา", align: "left", value: "subject_typeName" },
        { text: "รหัสสาขาวิชา", align: "left", value: "id_course_branch" },
        {
          text: "ชื่อสาขาวิชา",
          align: "left",
          value: "branch_name_th"
        },
        {
          text: "จำนวน",
          align: "center",
          value: "countAll"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ]
    };
  },
  created() {
    this.personnel_etcQueryAll();
  },

  methods: {
    async personnel_etcQueryAll() {
      let result = await this.$http.post("personnel_etc.php", {
        ApiKey: this.ApiKey,
        group_branch: "Ok"
      });
      this.personnel_etcs = result.data;
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
