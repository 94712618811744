<template>
  <div>

        <!-- สำนักงานอาชีวศึกษาจังหวัด ตรวจสอบคุณสมบัติ ผู้สมัครคัดเลือก รองผู้อำนวยการ -->
        <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="period_vecprovince_ap.period_vecprovince_enable === '1'"
      >
        <v-row cols="12" md="7">
          <v-col>
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <h2 class="secondary--text">
                <v-icon color="secondary">mdi-domain</v-icon>
                สำนักงานอาชีวศึกษาจังหวัด
              </h2>

              <h2>
                ตรวจสอบคุณสมบัติ ผู้สมัครคัดเลือก ตำแแหน่งรองผู้อำนวยการ
              </h2>
              <h3>    
                ก่อนระบบปิดในวันที่
                {{
                  period_vecprovince_ap.period_vecprovince_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
              </h3>
            </v-alert>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-center">
              <h3>
                ปิดระบบ :
                {{
                  period_vecprovince_ap.period_vecprovince_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_vecprovince_ap.period_vecprovince_stop +
                    ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>


          <!-- สำนักงานอาชีวศึกษาจังหวัด ตรวจสอบคุณสมบัติ ผู้สมัครคัดเลือก ผู้อำนวยการ -->
          <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="period_vecprovince_apdir.period_vecprovince_enable === '1'"
      >
        <v-row cols="12" md="7">
          <v-col>
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <h2 class="secondary--text">
                <v-icon color="secondary">mdi-domain</v-icon>
                สำนักงานอาชีวศึกษาจังหวัด
              </h2>

              <h2>
                ตรวจสอบคุณสมบัติ ผู้สมัครคัดเลือก ตำแแหน่ง ผู้อำนวยการวิทยาลัย
              </h2>
              <h3>    
                ก่อนระบบปิดในวันที่
                {{
                  period_vecprovince_apdir.period_vecprovince_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
              </h3>
            </v-alert>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-center">
              <h3>
                ปิดระบบ :
                {{
                  period_vecprovince_apdir.period_vecprovince_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_vecprovince_apdir.period_vecprovince_stop +
                    ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>




        <!-- สำนักงานอาชีวศึกษาจังหวัด ประเมิน ภาค ข รองผู้อำนวยการ -->
        <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="period_vecprovince_b.period_vecprovince_enable === '1'"
      >
        <v-row cols="12" md="7">
          <v-col>
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <h2 class="secondary--text">
                <v-icon color="secondary">mdi-domain</v-icon>
                สำนักงานอาชีวศึกษาจังหวัด
              </h2>

              <h2>
                ประเมิน ภาค ข ตำแแหน่งรองผู้อำนวยการ
              </h2>
              <h3>               
            
                ครั้งที่
                {{ period_vecprovince_b.period_vecprovince_times }} /
                {{ period_vecprovince_b.period_vecprovince_year }}
                ก่อนระบบปิดในวันที่
                {{
                  period_vecprovince_b.period_vecprovince_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
              </h3>
            </v-alert>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-center">
              <h3>
                ปิดระบบ :
                {{
                  period_vecprovince_b.period_vecprovince_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_vecprovince_b.period_vecprovince_stop +
                    ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>




    <!-- สำนักงานอาชีวศึกษาจังหวัด ครู -->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="period_vecprovinceTeacher.period_vecprovince_enable === '1'"
      >
        <v-row cols="12" md="7">
          <v-col>
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <h2 class="secondary--text">
                <v-icon color="secondary">mdi-domain</v-icon>
                สำนักงานอาชีวศึกษาจังหวัด
              </h2>

              <h3>
                ดำเนินการ
                <u>
                  <br />
                  เสนอรายชื่อคณะกรรมการ <br />
                  พิจารณาตรวจสอบการย้าย ตำแแหน่งครู
                </u>
                <br />
                ครั้งที่
                {{ period_vecprovinceTeacher.period_vecprovince_times }} /
                {{ period_vecprovinceTeacher.period_vecprovince_year }}
                ก่อนระบบปิดในวันที่
                {{
                  period_vecprovinceTeacher.period_vecprovince_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
              </h3>
            </v-alert>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-center">
              <h3>
                ปิดระบบ การพิจารณาตรวจสอบการย้าย ตำแแหน่งครู : <br />
                {{
                  period_vecprovinceTeacher.period_vecprovince_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_vecprovinceTeacher.period_vecprovince_stop +
                    ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>
    <!-- สำนักงานอาชีวศึกษาจังหวัด ผู้บริหาร-->
    <v-hover v-slot="{ hover }">
      <v-card
        :elevation="hover ? 16 : 2"
        :class="{ 'on-hover': hover }"
        class="pa-2"
        v-if="period_vecprovincemanage.period_vecprovince_enable === '1'"
      >
        <v-row cols="12" md="7">
          <v-col>
            <v-alert
              outlined
              prominent
              icon="mdi-bell-ring"
              type="error"
              border="left"
            >
              <h2 class="primary--text">สำนักงานอาชีวศึกษาจังหวัด</h2>

              <h3>
                ดำเนินการ <u> พิจารณาตรวจสอบการย้าย ตำแแหน่งครู </u> <br />
                ครั้งที่
                {{ period_vecprovincemanage.period_vecprovince_times }} /
                {{ period_vecprovincemanage.period_vecprovince_year }}
                ก่อนระบบปิดในวันที่
                {{
                  period_vecprovincemanage.period_vecprovince_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
              </h3>
            </v-alert>
          </v-col>
          <v-col cols="12" md="6">
            <div class="text-center">
              <h3>
                ปิดระบบ การพิจารณาตรวจสอบการย้าย ตำแแหน่งครู : <br />
                {{
                  period_vecprovincemanage.period_vecprovince_stop
                    | moment("add", "543 years")
                    | moment("D MMMM YYYY")
                }}
                <br />
                เหลือเวลาอีก
              </h3>
              <flip-countdown
                :deadline="
                  period_vecprovincemanage.period_vecprovince_stop + ' 16:30:00'
                "
                :labels="labels"
              ></flip-countdown>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import FlipCountdown from "vue2-flip-countdown";
export default {
  name: "HRvecAlertMissionProvince",
  components: { FlipCountdown },

  data() {
    return {
      ApiKey: "HRvec2021",
      period_vecprovinceTeacher: [],
      period_vecprovincemanage: [],
      labels: {
        days: "วัน",
        hours: "ชั่วโมง",
        minutes: "นาที",
        seconds: "วินาที"
      },
      period_vecprovince_b:[],
      period_vecprovince_ap:[],
      period_vecprovince_apdir:[],
    };
  },

  async mounted() {
    await this.period_vecprovinceTeacherQuery(); /* พิจารณาย้ายสายการสอน */
    await this.period_vecprovincemanageQuery(); /* พิจารณาย้ายสายบริหาร */
    await this.period_vecprovince_Approve(); /* ตรวจสอบคุณสมบัติ */
    await this.period_vecprovince_Approve_dir(); /* ตรวจสอบคุณสมบัติ */
    await this.period_vecprovince_bAs(); /* ประเมิน ภาค ข */


  },

  methods: {
    async period_vecprovince_Approve(){
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovinc_id: "201"
      });
      this.period_vecprovince_ap = result_period.data;

      if (this.period_vecprovince_ap.period_vecprovince_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_vecprovince_ap.period_vecprovince_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_vecprovince_ap.ApiKey = this.ApiKey;
            this.period_vecprovince_ap.period_vecprovince_enable = "0";
            await this.$http.post(
              "period_vecprovince.update.php",
              this.period_vecprovince_ap
            );
            await this.period_vecprovince_Approve();
          }
        }, 3000);
      }
    },


    async period_vecprovince_Approve_dir(){
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovinc_id: "401"
      });
      this.period_vecprovince_apdir = result_period.data;

      if (this.period_vecprovince_apdir.period_vecprovince_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_vecprovince_apdir.period_vecprovince_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_vecprovince_apdir.ApiKey = this.ApiKey;
            this.period_vecprovince_apdir.period_vecprovince_enable = "0";
            await this.$http.post(
              "period_vecprovince.update.php",
              this.period_vecprovince_apdir
            );
            await this.period_vecprovince_Approve_dir();
          }
        }, 3000);
      }
    },


    async period_vecprovince_bAs() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovinc_id: "20102"
      });
      this.period_vecprovince_b = result_period.data;

      if (this.period_vecprovince_b.period_vecprovince_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_vecprovince_b.period_vecprovince_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_vecprovince_b.ApiKey = this.ApiKey;
            this.period_vecprovince_b.period_vecprovince_enable = "0";
            await this.$http.post(
              "period_vecprovince.update.php",
              this.period_vecprovince_b
            );
            await this.period_vecprovince_bAs();
          }
        }, 3000);
      }
    },



    async period_vecprovinceTeacherQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovinc_id: "301"
      });
      this.period_vecprovinceTeacher = result_period.data;

      if (this.period_vecprovinceTeacher.period_vecprovince_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_vecprovinceTeacher.period_vecprovince_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_vecprovinceTeacher.ApiKey = this.ApiKey;
            this.period_vecprovinceTeacher.period_vecprovince_enable = "0";
            await this.$http.post(
              "period_vecprovince.update.php",
              this.period_vecprovinceTeacher
            );
            await this.period_vecprovinceTeacherQuery();
          }
        }, 3000);
      }
    },

    async period_vecprovincemanageQuery() {
      let result_period;
      result_period = await this.$http.post("period_vecprovince.php", {
        ApiKey: this.ApiKey,
        period_vecprovince_enable: "1",
        period_vecprovinc_id: "101"
      });
      this.period_vecprovincemanage = result_period.data;

      if (this.period_vecprovincemanage.period_vecprovince_enable == "1") {
        let dateNow = new Date();
        let dateStop = new Date(
          this.period_vecprovincemanage.period_vecprovince_stop + " 16:30:00"
        );
        this.dateNows = dateNow.getTime();
        this.dateStops = dateStop.getTime();
        this.interval = setInterval(async () => {
          if (this.dateNows > this.dateStops) {
            this.period_vecprovincemanage.ApiKey = this.ApiKey;
            this.period_vecprovincemanage.period_vecprovince_enable = "0";
            await this.$http.post(
              "period_vecprovince.update.php",
              this.period_vecprovincemanage
            );
            await this.period_vecprovincemanageQuery();
          }
        }, 3000);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
