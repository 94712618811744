var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.period_sedirectors_chooses.period_sedirector_enable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',{attrs:{"cols":"12","md":"7"}},[_c('v-col',[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('h2',{staticClass:"red--text"},[_c('v-icon',{attrs:{"color":"#C20000"}},[_vm._v("mdi-domain")]),_vm._v(" กจ2. สอ. สอศ. ")],1),_c('h2',{staticClass:"primary--text"},[_vm._v(" รายงานตัวเลือกสถานศึกษา ตำแหน่ง รองผู้อำนวยการวิทยาลัย ")]),_c('h3',[_vm._v(" ครั้งที่ "+_vm._s(_vm.period_sedirectors_chooses.period_sedirector_times)+"/"+_vm._s(_vm.period_sedirectors_chooses.period_sedirector_year)+" "),_c('br')])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"info","to":"/pages/sedirector_app_choose"}},[_vm._v("ติดตามการรายงานตัวเลือกวิทยาลัย")])],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.period_sedirectors.period_sedirector_enable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account")]),_vm._v(" เปิดระบบแนบไฟล์ การรับสมัครคัดเลือกตำแหน่ง รองผู้อำนวยการวิทยาลัย ")],1),_c('h3',[_vm._v("ให้ผู้สมัครที่ผ่านการคัดเลือก แนบไฟล์ประกอบการสมัคร")]),_c('h2',[_vm._v("สามารถดำเนินการ")]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_sedirectors.period_sedirector_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_sedirectors.period_sedirector_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_sedirectors.period_sedirector_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.period_directors.period_sedirector_enable === '1')?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account")]),_vm._v(" เปิดระบบ การรรับสมัครคัดเลือกตำแหน่ง ผู้อำนวยการวิทยาลัย ")],1),_c('h2',[_vm._v("สามารถดำเนินการ")]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_directors.period_sedirector_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.period_directors.period_sedirector_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.period_directors.period_sedirector_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(
        _vm.periodManages.period_enable === '1' &&
          _vm.periodManages.period_type === 'manage'
      )?_c('v-card',{staticClass:"pa-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-alert',{attrs:{"outlined":"","prominent":"","icon":"mdi-bell-ring","type":"error","border":"left"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h2',{staticClass:"primary--text"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account")]),_vm._v(" ผู้อำนวยการวิทยาลัย ")],1),_c('h2',[_vm._v("สามารถดำเนินการ "),_c('u',[_vm._v("ยื่นคำร้องขอย้าย ")]),(_vm.periodManages.period_normal_case==='1')?_c('u',[_vm._v("[ กรณีปกติ ] ")]):_vm._e(),(_vm.periodManages.period_quality_case==='1')?_c('u',{staticClass:"info--text"},[_vm._v(" [ เพื่อพัฒนาคุณภาพการศึกษา ]")]):_vm._e()]),_c('h3',[_vm._v(" ก่อนระบบปิดในวันที่ "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.periodManages.period_stop,"add", "543 years"),"D MMMM YYYY"))+" ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-center"},[_c('h3',[_vm._v(" ปิดระบบ "),_c('u',[_vm._v("ยื่นคำร้องขอย้าย")]),_vm._v(" : "+_vm._s(_vm._f("moment")(_vm._f("moment")(_vm.periodManages.period_stop,"add", "543 years"),"D MMMM YYYY"))+" "),_c('br'),_vm._v(" เหลือเวลาอีก ")]),_c('flip-countdown',{attrs:{"deadline":_vm.periodManages.period_stop + ' 16:30:00',"labels":_vm.labels}})],1)])],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }